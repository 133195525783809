
import { defineComponent , PropType} from 'vue';
import { formatPrice } from '@white-label-helper/helper-payment';
import type { GenerateProduct } from '@white-label-types/parking-booking';
import ProductSummaryPaymentGuests from './sub-components/guests-payment.vue'
import type { Fee, Tax } from '@white-label-types/parking-checkout';
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  name: 'ProductSummaryCardDropdown',
  filters: {
    formatPrice,
  },
  components:{
    ProductSummaryPaymentGuests
  },
  props: {
    displayLocation: {
      type: String,
      default: 'basket'
    },
    product: {
      type: Object as PropType<GenerateProduct>,
      required: true,
    },
    isOpen: {
        type: Boolean,
        default: false
    }
  },
  computed:{
    taxes(): Tax[] {
      return !getAppVariable('is_tax_inclusive')
        ? this.product.taxes
        : [];
    },

    fees(): Fee[] {
      return !getAppVariable('is_fee_inclusive')
        ? this.product.fees
        : [];
    },
    additionalPaymentInfos(): string[] {
      let components = [];

      if(this.product.product_code === 'lounges' || this.product.product_code === 'fasttrack') {
        components.push('ProductSummaryPaymentGuests');
      }

      return components;
    }
  },
})

