import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4f7b9e0a = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _b4dcd55a = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _3a1444b8 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _19ef9007 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _be246410 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _a4d78bd0 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _80c989b2 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _65624014 = () => interopDefault(import('../src/pages/select-extras.vue' /* webpackChunkName: "pages/select-extras" */))
const _27baa5e4 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _3f08c160 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _adfc56ca = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _cbdc2b50 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _650c1d8a = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _d5274248 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _50139a7e = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _52765cdc = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _4f7b9e0a,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _b4dcd55a,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _3a1444b8,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _19ef9007,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _be246410,
    name: "receipt"
  }, {
    path: "/search",
    component: _a4d78bd0,
    name: "search"
  }, {
    path: "/search-booking",
    component: _80c989b2,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _65624014,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _27baa5e4,
    name: "terms-and-conditions"
  }, {
    path: "/unsupported-browser",
    component: _3f08c160,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _adfc56ca,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _cbdc2b50,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _650c1d8a,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _d5274248,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _50139a7e,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _52765cdc,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
