
// Packages
import { defineComponent } from 'vue';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getDomainUrl, DOMAINS_NAMES } from '@white-label-helper/switch-app';

// Stores
import { readDiscountQuery } from '@white-label-store/discount-code';

// Constants
import {
  NAMED_ROUTES,
  PROGRESS_INDICATOR_PAGES,
} from '@white-label-configuration/constants';

// Components
import {
  readCartItems,
  readCartItemsType,
} from '@white-label-store/cart-checkout';

// Components
import ProgressIndicator from '../progress-indicator/progress-indicator.vue';
import Navbar from '../navbar/navbar.vue';


export default defineComponent({
  name: 'CheckoutNavBar',

  components: {
    NavbarCheckout: Navbar,
    ProgressIndicator,
  },

  data() {
    return {
      NAMED_ROUTES,
    };
  },

  computed: {
    cartItemsType(): ReturnType<typeof readCartItemsType> {
      return readCartItemsType(this.$store);
    },

    isLounges(): boolean {
      return this.cartItemsType === 'lounges';
    },

    cartItems(): ReturnType<typeof readCartItems> {
      return readCartItems(this.$store);
    },

    isCancellationProtectionAvailable(): boolean {
      return this.cartItems?.[0]?.inventory_option?.details
        ?.cancellation_protection?.is_available;
    },

    bookingAppUrls(): { [key: string]: string } {
      return {
        home: `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.booking_app.home}`,
        search: `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.booking_app.search}`,
        select_extras: `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.booking_app.select_extras}`,
      };
    },

    discountQuery(): ReturnType<typeof readDiscountQuery> {
      return readDiscountQuery(this.$store);
    },

    displayProgressIndicator(): boolean {
      // Need to case this as 'checkout' out as it's the only value it's looking for and won't accept a string
      const routeName = this.$route.name as 'checkout';
      return (
        this.cartItemsType !== '' &&
        Object.values(PROGRESS_INDICATOR_PAGES).includes(routeName)
      );
    },

    partner_name() {
      return getAppVariable('partner_name');
    },

    logoURL() {
      return getAppVariable('logo');
    },

    logoRedirectURL() {
      return (
        getDomainUrl(DOMAINS_NAMES.ECOMMERCE) ||
        getAppVariable('logo_redirect_url')
      );
    },

    shouldSkipExtrasStep(): boolean {
      // TODO: Remove condition when flag `ECOM_1771_MOVE_CANCELLATION_PROTECTION` is enabled
      if (this.$launchDarkly.variation('ECOM_1771_MOVE_CANCELLATION_PROTECTION') === true) {
        const productTypesAvailable = getAppVariable('product_types_available') ?? [];
        return !productTypesAvailable.includes('lounges');
      }
      return !this.isCancellationProtectionAvailable;
    },
  },
});
