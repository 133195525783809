var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bookings container"},[_c('div',{staticClass:"bookings__top-head"},[_c('BackButton',{on:{"btnClick":_vm.goToBookingHome}}),_vm._v(" "),_c('div',{staticClass:"bookings__summary"},[_c('div',{staticClass:"summary"},[_c('div',{staticClass:"summary__item"},[_c('p',{staticClass:"summary__item-title"},[_vm._v("\n            "+_vm._s(_vm.$t('guestDetails.product'))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"summary__item-value summary__item-value--product"},[_vm._v("\n            "+_vm._s(_vm.inventoryItem?.display_name ?? '-')+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"summary__item"},[_c('p',{staticClass:"summary__item-title"},[_vm._v("\n            "+_vm._s(_vm.$t('guestDetails.flightNumber'))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"summary__item-value summary__item-value--flight-number"},[_vm._v("\n            "+_vm._s(_vm.inventoryItem?.airline_name)+"/"+_vm._s(_vm.flightNumber)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"summary__item"},[_c('p',{staticClass:"summary__item-title"},[_vm._v("\n            "+_vm._s(_vm.$t('guestDetails.tripType'))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"summary__item-value"},[_c('span',{staticClass:"item-tag"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  `UI.productSummaryCard.tags.privateLounge.${_vm.inventoryItem?.direction}`
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"summary__item"},[_c('p',{staticClass:"summary__item-title"},[_vm._v("\n            "+_vm._s(_vm.$t('guestDetails.flightTime'))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"summary__item-value"},[_vm._v("\n            "+_vm._s(_vm.getFlightTime(
                _vm.isInboundProduct
                  ? _vm.inventoryItem?.entry_date
                  : _vm.inventoryItem?.exit_date
              ))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"summary__item"},[_c('p',{staticClass:"summary__item-title"},[_vm._v("\n            "+_vm._s(_vm.$t('guestDetails.guests'))+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"summary__item-value"},[_vm._v("\n            "+_vm._s(_vm.getGuestsInfo(_vm.order.groups))+"\n          ")])])])])],1),_vm._v(" "),(_vm.$contentPreloaderEnabled)?_c('BookingsPreloader'):[_c('div',{staticClass:"bookings__head"},[_c('h1',{staticClass:"bookings__title"},[_vm._v("\n        "+_vm._s(_vm.$t('guestDetails.guests'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"detail-card-list"},_vm._l((_vm.guestsCards),function(guestCard){return _c('BookingDetailsCard',{key:guestCard.title,attrs:{"data-testid":"details-guest-card","title":guestCard.title,"subtitle":guestCard.subtitle}})}),1),_vm._v(" "),_c('div',{staticClass:"bookings__head"},[_c('h1',{staticClass:"bookings__title"},[_vm._v("\n        "+_vm._s(_vm.$t('guestDetails.baggageDetails'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"detail-card-list"},[_c('BookingDetailsCard',{attrs:{"title":_vm.$t('guestDetails.bags')},on:{"add-details-click":_vm.goToBaggageDetailsPage}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }