var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cancellation-and-amendment-policy"},[_c('p',{staticClass:"cancellation-and-amendment-policy__title"},[_vm._v("\n    "+_vm._s(_vm.$t('cancellationAndAmendment.cancellationAndAmendment'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"cancellation-and-amendment-policy__block"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('RadioButton',{attrs:{"value":_vm.selectedOption,"data-testid":"standard-policy-radio","name":"policy_radio","option":"standardOption","label":_vm.$t('cancellationAndAmendment.standardPolicy')},on:{"input":_vm.onRadioSelect}}),_vm._v(" "),_c('button',{staticClass:"p-2",attrs:{"data-testid":"standard-policy-toggle"},on:{"click":function($event){$event.preventDefault();_vm.standardPolicyExpanded = !_vm.standardPolicyExpanded}}},[_c('IconArrow',{class:[
            'text-brand-100 transform',
            { 'rotate-180': _vm.standardPolicyExpanded },
          ]})],1)],1),_vm._v(" "),(_vm.standardPolicyExpanded)?_c('div',{staticClass:"w-full mt-4",attrs:{"data-testid":"standard-policy-content"}},[(_vm.cancellationPolicies.length)?[_c('p',{staticClass:"text-sm font-medium"},[_vm._v("\n          "+_vm._s(_vm.$t('cancellationAndAmendment.cancellationPolicy'))+"\n        ")]),_vm._v(" "),_vm._l((_vm.cancellationPolicies),function(item,index){return _c('p',{key:`cancellation_policy_${index}`,staticClass:"text-sm text-mag-elements-icons-on-dark mt-1",attrs:{"data-testid":"standard-policy-cancellation"}},[_vm._v("\n          "+_vm._s(item.message)+"\n        ")])})]:_vm._e(),_vm._v(" "),(_vm.amendmentPolicies.length)?[_c('p',{staticClass:"text-sm font-medium mt-2"},[_vm._v("\n          "+_vm._s(_vm.$t('cancellationAndAmendment.amendmentPolicy'))+"\n        ")]),_vm._v(" "),_vm._l((_vm.amendmentPolicies),function(item,index){return _c('p',{key:`amendment_policy_${index}`,staticClass:"text-sm text-mag-elements-icons-on-dark mt-1",attrs:{"data-testid":"standard-policy-amendment"}},[_vm._v("\n          "+_vm._s(item.message)+"\n        ")])})]:_vm._e()],2):_vm._e()]),_vm._v(" "),(_vm.isCancellationProtectionAvailable)?_c('div',{staticClass:"cancellation-and-amendment-policy__block mt-5",attrs:{"data-testid":"upgraded-protection-block"}},[_c('div',{staticClass:"flex items-center justify-between"},[_c('RadioButton',{attrs:{"value":_vm.selectedOption,"data-testid":"upgraded-protection-radio","name":"policy_radio","option":"upgradedOption","label":_vm.upgradedProtectionLabel},on:{"input":_vm.onRadioSelect}}),_vm._v(" "),_c('button',{staticClass:"p-2",attrs:{"data-testid":"upgraded-protection-toggle"},on:{"click":function($event){$event.preventDefault();_vm.upgradedProtectionExpanded = !_vm.upgradedProtectionExpanded}}},[_c('IconArrow',{class:[
            'text-brand-100 transform',
            { 'rotate-180': _vm.upgradedProtectionExpanded },
          ]})],1)],1),_vm._v(" "),(_vm.upgradedProtectionExpanded)?_c('div',{staticClass:"w-full mt-4",attrs:{"data-testid":"upgraded-protection-content"}},[_c('div',{staticClass:"flex md:items-center mb-2"},[_c('IconShield',{staticClass:"shrink-0 text-green-accent mr-2 mt-1 md:mt-0"}),_vm._v(" "),_c('p',{staticClass:"text-sm text-mag-elements-icons-on-dark"},[_vm._v("\n          "+_vm._s(_vm.$t('cancellationAndAmendment.protectYourBooking'))+":\n        ")])],1),_vm._v(" "),_vm._l((_vm.cancellationProtection),function(product,productIndex){return _c('div',{key:`cancellation_protection_policy_product_${productIndex}`,staticClass:"mb-2",attrs:{"data-testid":"cancellation_protection_policy"}},[(product.is_available)?_c('p',[_c('span',{staticClass:"text-sm text-mag-elements-icons-on-dark mb-2 font-medium"},[_vm._v("\n            "+_vm._s(product.product_name)+":\n          ")]),_vm._v(" "),_vm._l((product.cancellation_policies),function(policy,policyIndex){return _c('span',{key:`cancellation_protection_policy_${policyIndex}`,staticClass:"text-sm text-mag-elements-icons-on-dark mb-2",attrs:{"data-testid":"cancellation_protection_policy_message"}},[_vm._v("\n            "+_vm._s(policy.message)+"\n          ")])})],2):_vm._e()])}),_vm._v(" "),_c('p',{staticClass:"text-sm text-mag-elements-icons-on-dark"},[_vm._v("\n        "+_vm._s(_vm.$t('cancellationAndAmendment.standardPolicyIncluded'))+"\n      ")])],2):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }