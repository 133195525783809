
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductTags',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tags() {
      let tags = [];

      if (this.isPrivateLounge) {
        tags.push(
          this.tagTranslation(
            'privateLounge',
            this.privateLoungeFlyingDirection
          )
        );
      }
      if (this.product.productHeader?.product_code === 'fasttrack') {
        tags.push(this.tagTranslation('fasttrack', this.productOption));
      }
      return tags;
    },
    isPrivateLounge() {
      return this.productOption === 'lounge_private';
    },
    privateLoungeFlyingDirection() {
      let flyingDirection;

      if (this.isSearchProduct && this.product.productOptions.length) {
        flyingDirection = this.product.productOptions[0]?.meta?.direction;
      } else {
        flyingDirection = this.product.inventory_item?.direction;
      }

      return (flyingDirection ?? 'inbound').toLowerCase();
    },
    productOption() {
      if (
        this.isSearchProduct &&
        this.product &&
        Object.hasOwnProperty.call(this.product, 'productOptions')
      ) {
        return this.product?.productOptions[0]?.meta?.product_option_code;
      }

      return this.product?.inventory_item?.product_option?.code;
    },
    isSearchProduct() {
      return !this.product.hasOwnProperty('inventory_item');
    },
  },
  methods: {
    tagTranslation(section, key) {
      return this.$t(`UI.productSummaryCard.tags.${section}.${key}`);
    },
  },
});
