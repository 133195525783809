
import { defineComponent } from 'vue';

// Mixins
import auth from '@white-label-helper/mixin-auth';
import BackButton from '../back-button/back-button.vue';
import BookingsPreloader from '../bookings-preloader/bookings-preloader.vue';
import { NAMED_ROUTES } from '@white-label-configuration/constants';
import { ManageBookingState } from '@white-label-types/stores';
import {
  readBooking,
  readInventoryItem,
  readManageBookingItems,
} from '@white-label-store/manage-booking';
import { TranslateResult } from 'vue-i18n';
import { DATE_TIME_FORMATS, format } from '@white-label-helper/date-utilities';
import { OrderItem } from '@white-label-types/account-booking';
import BookingDetailsCard from '../booking-details-card/booking-details-card.vue';
import { capitalize } from 'lodash';

export default defineComponent({
  name: 'BookingGuestsInfo',
  components: {
    BookingDetailsCard,
    BookingsPreloader,
    BackButton,
  },
  mixins: [auth],
  computed: {
    getId() {
      const id = this.$cookies.get('manageBookingToken')?.id;
      return id;
    },
    order(): ManageBookingState['manageBookingState'] {
      return readBooking(this.$store);
    },
    inventoryItem() {
      return !this.getId ? readInventoryItem(this.$store) : this.manageBookingItems;
    },
    manageBookingItems() {
      return (readManageBookingItems(this.$store).find(booking => booking.id === this.getId) || {}).inventory_item;
    },
    isInboundProduct(): boolean {
      return this.inventoryItem?.direction === 'inbound';
    },
    flightNumber(): string {
      const flight = this.isInboundProduct
        ? this.order.user?.inbound_flight
        : this.order.user?.outbound_flight;

      return flight || '-';
    },
    guestsCards() {
      const guests = [];
      let globalGuestsCounter = 1;
      guests.push({
        title: `${this.$t('guestDetails.guest')} 1`,
        subtitle: `${this.order?.user?.first_name} ${this.order?.user?.last_name}`,
      });

      const groups = this.order?.searchCriteria?.lounges?.groups ?? {};
      const totalGuestsQuantity = Object.values(groups).reduce(
        (sum, quantity) => sum + quantity,
        0
      );

      if (totalGuestsQuantity > 1) {
        for (const [group, quantity] of Object.entries(groups)) {
          let loopQuantity = quantity;
          if (globalGuestsCounter < 2) {
            loopQuantity = quantity - 1;
            globalGuestsCounter++;
          }
          for (let i = 0; i < loopQuantity; i++) {
            guests.push({
              title: `${this.$t('guestDetails.guest')} ${globalGuestsCounter}`,
              subtitle: `${capitalize(group)} ${i + 1}`,
            });
            globalGuestsCounter++;
          }
        }
      }

      return guests;
    },
  },
  methods: {
    goToBookingHome() {
      this.$router.push(NAMED_ROUTES.booking_app.home);
    },

    getFlightTime(flightDateTime: string): TranslateResult | string {
      if (!flightDateTime) {
        return '';
      }
      const flightTime = format(
        new Date(flightDateTime),
        DATE_TIME_FORMATS.day_month_year_day_time_am_pm_lower_case
      );

      return this.$t('UI.productSummaryCard.info.flightTime', { flightTime });
    },

    getGuestsInfo(guests: OrderItem['groups']): string {
      const guestsInfo = [];
      for (const group of Object.values(guests)) {
        guestsInfo.push(group);
      }
      return guestsInfo.join(', ');
    },
    goToBaggageDetailsPage() {
      this.$router.push(NAMED_ROUTES.booking_baggage_details.home)
    }
  },
});
