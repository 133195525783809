
import { defineComponent } from 'vue';
import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';
import BTicketGuests from '../b-ticket-guests/b-ticket-guests.vue';
import InfoPrivateLounge from '../b-ticket-private-lounge-details/b-ticket-private-lounge-details.vue';

export default defineComponent({
  name: 'BTicketBookingDetails',
  components: {
    BTicketGuests: BTicketGuests,
    EntryExitInfo: EntryExitInfo,
    PrivateLoungeInfo: InfoPrivateLounge,
  },
  props: {
    subItems: {
      type: Array,
      default() {
        return [];
      },
    },

    entryTime: {
      type: String,
      default: '',
    },

    product: {
      type: Object,
      required: true,
    },

    exitTime: {
      type: String,
      default: '',
    },

    entryDateTime: {
      type: String,
      default: '',
    },

    exitDateTime: {
      type: String,
      default: '',
    },
  },
  computed: {
    productOption() {
      return (
        this.product?.inventory_item?.product_option?.code ||
        this.product?.inventoryItem?.product_option?.code
      );
    },
    isPrivateLounge() {
      return this.productOption === 'lounge_private';
    },
  },
});
