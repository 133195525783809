
import { defineComponent } from 'vue';
import BookingItem from '../booking-item/booking-item.vue';
import { ManageBookingState } from '@white-label-types/stores';
// Mixins
import auth from '@white-label-helper/mixin-auth';
import {
  readBooking,
} from '@white-label-store/manage-booking';

export default defineComponent({
  name: 'BookingProductsCards',
  mixins: [auth],
  components: {
    BookingItem,
  },
  computed: {

    order(): ManageBookingState['manageBookingState'] {
      return readBooking(this.$store);
    },
    orderItems(): ManageBookingState['manageBookingState'][any] {
      return (this.order || {}).items.map((item) => {
        const groupsObj = {};
        for (const [group, value] of Object.entries(
          item.inventory_option?.details?.groups
        )) {
          groupsObj[group] = (value.total || {}).quantity || 1;
        }
        return {
          privateTerminalBooking: true,
          status: item.status,
          id: this.order.orderId,
          productId: item.id,
          ...{
            orderItems: [
              {
                ...item.inventory_item,
                displayName: item.inventory_item?.display_name,
                startedAt: { datetime: item.inventory_item?.entry_date },
                closedAt: { datetime: item.inventory_item?.exit_date },
                groups: groupsObj,
                 baggageType: item.inventory_item?.baggage_type,
                  mealType: item.inventory_item?.meal_type,
                  airlineName: item.inventory_item?.airline_name
              },
            ],
          },
        };
      });
    },
  },
});
