
import { defineComponent } from 'vue';
import BookingStatus from '../booking-status/booking-status.vue';
import ControlButton from '../button/button.vue';

export default defineComponent({
  name: 'BookingDetailsCard',
  components: { ControlButton, BookingStatus },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
});
