
// Packages
import { defineComponent, PropType } from 'vue';

// Types
import type VueI18n from 'vue-i18n';
import type { Partners } from '@white-label-types/partners';
import type { MenuData, TabData } from '@white-label-types/navbar';

// Helpers
import {
  ACCOUNT_URLS,
  MANAGE_BOOKING_URLS,
  DOMAINS_NAMES,
  goToApp,
  getDomainUrl,
} from '@white-label-helper/switch-app';
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Mixins
import auth from '@white-label-helper/mixin-auth';
import bookingPortal from '@white-label-helper/mixin-booking-portal';

// Constants
import { COLOUR_SCHEMES } from '@white-label-configuration/constants';

// Components
import Logo from '../logo/logo.vue';
import UserMenuDesktop from '../user-menu-desktop/user-menu-desktop.vue';
import UserMenuMobile from '../user-menu-mobile/user-menu-mobile.vue';
import NavbarTabs from '../navbar-tabs/navbar-tabs.vue';
import NavbarPartnerLink from '../navbar-partner-link/navbar-partner-link.vue';

type ColourScheme = (typeof COLOUR_SCHEMES)[keyof typeof COLOUR_SCHEMES];


export default defineComponent({
  name: 'NavbarParkings',

  components: {
    Logo,
    UserMenuDesktop,
    UserMenuMobile,
    NavbarTabs,
    NavbarPartnerLink,
  },

  mixins: [auth, bookingPortal],

  props: {
    tabsData: {
      type: Object as PropType<TabData>,
      required: false,
      default: () => ({}),
    },

    showLoginFlow: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    /** The URL for the logo */
    logoUrl(): Partners['logo'] {
      return getAppVariable('logo');
    },

    /** The URL to send a customer when clicking on the logo */
    homePageUrl(): string | null {
      let logoRedirectURL =
        getDomainUrl(DOMAINS_NAMES.ECOMMERCE) ||
        getAppVariable('logo_redirect_url');
      const discountFromQuery = this.$route.query.discount;

      if (!logoRedirectURL) {
        return null;
      }

      if (discountFromQuery && typeof discountFromQuery === 'string') {
        logoRedirectURL += `/?discount=${discountFromQuery}`;
      }

      return `https://${logoRedirectURL}`;
    },

    /**
     * Checks if login is enabled or not on Launch Darkly.
     */
    loginEnabled(): boolean {
      if (!process.client || !this.$launchDarkly) {
        return false;
      }
      return (
        this.$launchDarkly.variation(
          'WL-FeatureControlPerPartnerEnableMyAccount'
        ) === true
      );
    },

    /**
     * Returned of the user is logged in or not
     */
    loggedInUser(): boolean {
      // @ts-ignore - global mixin computed property
      return this.$isLoggedIn;
    },

    /**
     * Login button text is based on if the feature toggle is in, and if the customer is login in or not
     */
    loginButtonText(): VueI18n.TranslateResult {
      console.log('loginEnabled', this.loginEnabled)
      return this.loginEnabled
        ? this.$t('shared.buttons.login')
        : this.$t('UI.tabs.myBookings');
    },

    /**
     * What URL to pass in based on if logging in is enabled and if the user is login or not
     */
    accountUrl(): ACCOUNT_URLS {
      if (!this.loginEnabled) {
        return ACCOUNT_URLS.SEARCH_BOOKING;
      }

      return this.loggedInUser ? ACCOUNT_URLS.HOME : ACCOUNT_URLS.LOGIN;
    },

    /**
     * Checks if the tab data passed in have any tabs to display
     */
    hasTabLinks(): boolean {
      return 'tabsLinks' in this.tabsData;
    },

    showMyBookingsButton(): boolean {
      return this.showLoginFlow ?? !this.loggedInUser;
    },

    menuData(): MenuData {
      return this.loggedInUser
        ? {
            nickname: this.auth0User?.name,
            logoutHandler: () => this.logout(),
            makeNewBookingHandler: () =>
              goToApp(
                getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
                MANAGE_BOOKING_URLS.HOME
              ),
          }
        : undefined;
    },

    colourScheme(): ColourScheme {
      const scheme = getAppVariable('colours.footer.font');
      if (scheme === COLOUR_SCHEMES.LIGHT || scheme === COLOUR_SCHEMES.DARK) {
        return scheme;
      }
      return COLOUR_SCHEMES.DARK;
    },
  },

  methods: {
    goToMyBookings(): void {
      goToApp(
        getDomainUrl(DOMAINS_NAMES.ACCOUNT),
        this.accountUrl
      );
    },
  },
});
