
// Packages
import { defineComponent } from 'vue';

// Components
import RadioButtonGroup from '../radio-button-group/radio-button-group.vue';
import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';
import TextField from '../text-field/text-field.vue';
import TextFieldDate from '../text-field-date/text-field-date.vue';
import PhoneField from '../phone-field/phone-field.vue';

export default defineComponent({
  name: 'PostSalesQuestionnaire',
  components: {
    TextField,
    TextFieldDate,
    PhoneField,
    DropdownWrapper,
    RadioButtonGroup,
  },
  data() {
    return {
      loading: false,
      issuingCountry: { id: 1, name: 'United Kingdom' },
      nationality: { id: 1, name: 'United Kingdom' },
      leadAssistance: 'no',
      assistanceOptions: {
        no: this.$t('postSales.assistanceNotRequired'),
        yes: this.$t('postSales.assistanceRequired'),
      },
      specialAssistance: {
        id: '1',
        name: this.$t('postSales.travelWithAGuide'),
      },
      specialAssistanceDropdown: [
        {
          id: 1,
          name: this.$t('postSales.travelWithAGuide'),
        },
      ],
    };
  },
});
