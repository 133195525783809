
import { defineComponent, PropType } from 'vue';
// Constants

// Types
import type {
  CartItem,
  OrderTotals,
  Receipt,
} from '@white-label-types/parking-checkout';
import type { TaxFee } from '@white-label-types/parking-booking';

// Helpers
import VueI18n from 'vue-i18n';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';
import { formatPrice } from '@white-label-helper/helper-payment';

// Components
import SummaryPreloader from '../summary-preloader/summary-preloader.vue';
import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';
import IconAlert from '@white-label-icon/icon-alert';
import IconCheck from '@white-label-icon/icon-check';
import IconMessage from '../icon-message/icon-message.vue';
import SummaryProduct from '../summary-product/summary-product.vue';
import SummarySubtitle from '../summary-subtitle/summary-subtitle.vue';
import ProductSummaryCard from '../product-summary-card/product-summary-card.vue';

// Stores
import { readDepositOnly } from '@white-label-store/deposits'


type SummaryElementItems = CartItem[] | Receipt['items'];
type SummaryElementItem = SummaryElementItems extends (infer U)[] ? U : never;
type SubItems = {
  title: string;
  value: string | Intl.NumberFormat;
  isDiscount?: boolean;
};

export default defineComponent({
  name: 'SummaryLounges',

  components: {
    IconAlert,
    IconCheck,
    IconMessage,
    EntryExitInfo,
    SummaryPreloader,
    SummaryProduct,
    SummarySubtitle,
    ProductSummaryCard
  },

  filters: { formatPrice },

  props: {
    amountToPay: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    contentLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<SummaryElementItems>,
      default: () => [] as SummaryElementItems,
    },
    originalOrder: {
      type: Object as PropType<SummaryElementItems>,
      required: false,
      default: () => ({}),
    },
    hasPaid: {
      type: Boolean,
      default: false,
    },
    isModifyFlowReceipt: {
      type: Boolean,
      default: false,
    },
    isRefund: {
      type: Boolean,
      default: false,
    },
    orderTotals: {
      type: Object as PropType<OrderTotals>,
      default: () => ({}),
    },
    priceDifference: {
      type: String,
      default: '',
    },
    amendmentFee: {
      type: String,
      default: '',
    },
    cancellationFee: {
      type: String,
      default: '',
    },
    cancellationProtection: {
      type: String,
      default: '',
    },
    refundDoesNotExist: {
      type: Boolean,
      default: false,
    },
    refundProcessing: {
      type: Boolean,
      default: false,
    },

    valueAfterRefundWithoutCancellation: {
      type: String,
      default: '',
    },

    originalOrderHasCancellationProtection: {
      type: Boolean,
      default: false,
    },

    hideEntryInfo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isExpanded: false,
      isCancellationProtectionExpanded: false,
    };
  },
  computed: {
    isModifyCheckout(): boolean {
      return !!this.originalItemTotalPrice;
    },
    displayCancellationProtectionItems(): boolean {
      return (
        !!this.cancellationSubItems.length &&
        this.isCancellationProtectionExpanded
      );
    },
    displayNewSummaries(){
      return this['$launchDarkly'].variation('PT-1566-DISPLAY-NEW-PAYMENT-SUMMARIES');
    },
    displaySummaryBottomSectionCancelled(): boolean | string {
      return !!this.cancellationProtection || this.cancellationFee;
    },

    originalOrderProps(): { [key: string]: any } {
      const item = this.originalOrder;
      if (!item.inventory_option) {
        return {};
      }
      const sub_options = item.inventory_option.sub_options || [];
      const labels: { [key: string]: string } = {
        senior: 'Senior',
        adult: 'Adult',
        child: 'Child',
        infant: 'Infant',
      };
      const subItems: SubItems[] = [];
      const inventoryMap: { [key: string]: any } = {};
      sub_options.forEach((listItem) => {
        if (listItem?.details?.name) {
          inventoryMap[listItem.details.name] = listItem;
        }
      });
      ['senior', 'adult', 'child', 'infant'].forEach((key) => {
        const item = inventoryMap[key];
        if (!item) {
          return;
        }
        // Added label[key] until cart data response has added name_formatted
        const name = item.details?.name_formatted
          ? item.details.name
          : labels[key];
        const totals =
          'original_amounts' in item
            ? item.original_amounts.totals
            : item.totals;
        const quantity = item.quantity || totals.quantity;
        if (quantity) {
          const label = `${quantity} x ${name}`;
          subItems.push({
            title: label,
            value: formatPrice(totals.subtotal),
          });
          if (item.discount) {
            subItems.push({
              isDiscount: true,
              title: item.discount.description,
              value: formatPrice(item.discount.amount),
            });
          }
        }
      });

      const displayName =
        item?.inventory_item && 'display_name' in item.inventory_item
          ? item.inventory_item.display_name
          : '';

      const orderName = displayName || item?.inventory_item?.name || '';

      return {
        taxes: item.taxes,
        fees: item.fees,
        orderName,
        subItems,
      };
    },
    props(): { [key: string]: any } {
      const { items } = this;
      const item = items[0];
      const sub_options = item.inventory_option.sub_options || [];
      const cancellationProtectionSum =
        item?.cancellation_protection?.totals?.total || 0;
      const is_protected = items.some(
        (listItem: SummaryElementItem) =>
          'isCancellable' in listItem ||
          ('is_cancellable' in listItem && cancellationProtectionSum) ||
          ('is_protected' in listItem && listItem.is_protected)
      );
      const labels: { [key: string]: string } = {
        senior: 'Senior',
        adult: 'Adult',
        child: 'Child',
        infant: 'Infant',
      };
      const subItems: SubItems[] = [];
      const inventoryMap: { [key: string]: any } = {};
      sub_options.forEach((listItem) => {
        if (listItem?.details?.name) {
          inventoryMap[listItem.details.name] = listItem;
        }
      });
      ['senior', 'adult', 'child', 'infant'].forEach((key) => {
        const item = inventoryMap[key];
        if (!item) {
          return;
        }
        // Added label[key] until cart data response has added name_formatted
        const name = item.details?.name_formatted
          ? item.details.name_formatted
          : labels[key];
        const totals =
          'original_amounts' in item
            ? item.original_amounts.totals
            : item.totals;
        const quantity = item.quantity || totals.quantity;
        if (quantity) {
          const label = `${quantity} x ${name}`;
          subItems.push({
            title: label,
            value: formatPrice(totals.subtotal),
          });
          if (item.discount) {
            subItems.push({
              isDiscount: true,
              title: item.discount.description,
              value: formatPrice(item.discount.amount),
            });
          }
        }
      });

      let entryDateTime = '';
      let exitDateTime = '';
      if ('search_criteria' in item) {
        const search_criteria = (item.search_criteria.lounges ||
          item.search_criteria) as CartItem['search_criteria']['lounges'];
        if (search_criteria) {
          entryDateTime = `${search_criteria.date1}T${search_criteria.time1}`;
          exitDateTime = `${search_criteria.date1}T${search_criteria.time1}`;
        }
      } else {
        entryDateTime = item?.criteria?.started_at?.datetime || '';
        exitDateTime = item?.criteria?.closed_at?.datetime || '';
      }

      const totals =
        'original_amounts' in item
          ? item.original_amounts.totals
          : (item as SummaryElementItem).totals;

      const displayName =
        item?.inventory_item && 'display_name' in item.inventory_item
          ? item.inventory_item.display_name
          : '';

      const orderName = displayName || item?.inventory_item?.name || '';

      return {
        entryDateTime,
        exitDateTime,
        taxes: item.taxes,
        fees: item.fees,
        orderName,
        orderPrice: formatPrice(totals?.total),
        totalValue: formatPrice(
          item.totals.total + (is_protected ? cancellationProtectionSum : 0)
        ),
        totalValueInt:
          item.totals.total + (is_protected ? cancellationProtectionSum : 0),
        cancellationProtection: is_protected
          ? formatPrice(cancellationProtectionSum)
          : '',
        subItems,
        itemSubtotal: formatPrice(item.totals.subtotal),
        status: item.status,
      };
    },

    isAmending(): boolean {
      const item = this.items[0];
      return item && 'meta' in item && !!item.meta?.original_order_item_id;
    },
    originalItemTotalPrice(): string {
      const item = this.items[0];
      if ('meta' in item) {
        return `${item?.meta?.original_order_item_total_price || ''}`;
      }
      return '';
    },
    cancellationSubItems(): SubItems[] {
      const results: SubItems[] = [];
      const { items }: { items: SummaryElementItems } = this;
      if (items && this.items.length) {
        items.forEach((item: SummaryElementItem) => {
          if (
            item.cancellation_protection &&
            'taxes' in item.cancellation_protection
          ) {
            (item.cancellation_protection.taxes as TaxFee[]).forEach(
              ({ name, amount }) =>
                results.push({
                  title: name,
                  value: `${formatPrice(amount)}`,
                })
            );
          }
        });
      }
      return results;
    },
    totalLabel(): VueI18n.TranslateResult {
      if (this.hasDeposit && !(this.isReceiptPage || this.isManageBookingPages)) {
        return this.$t('shared.payNow');
      }

      if (this.isModifyFlowReceipt) {
        if (this.amountToPay !== null && this.amountToPay < 0) {
          return this.$t('shared.refundAmount');
        }
        if (this.amountToPay !== null && this.amountToPay > 0) {
          return this.$t('shared.amountPaid');
        }
        return this.$t('shared.priceDifference');
      }
      if (this.amountToPay !== null && this.amountToPay > 0) {
        return this.$t('shared.totalToPay');
      }
      if (this.isRefund) {
        if (this.amountToPay === 0) {
          return this.$t('shared.totalToPay');
        }

        if (this.hasDeposit) {
          return this.$t('shared.amountRefund');
        }
        return this.$t('shared.refundAmount');
      }
      if (this.hasPaid) {
        return this.$t('shared.totalPaid');
      }
      return this.$t('shared.totalToPay');
    },
    isOrderCancelled(): boolean {
      return this.items[0].status === BOOKING_STATUSES.CANCELLED;
    },
    orderTotalFormatted(): string | Intl.NumberFormat {
      return typeof this.orderTotals?.total === 'number'
        ? formatPrice(this.orderTotals.total)
        : '';
    },
    totalValue(): string | Intl.NumberFormat {
      if (this.hasDeposit) {
        return this.hasDeposit.payNow;
      }

      if (!this.isModifyFlowReceipt) {
        if (this.valueAfterRefundWithoutCancellation) {
          return this.valueAfterRefundWithoutCancellation;
        }

        return this.isRefund || this.isModifyCheckout
          ? this.priceDifference
          : this.orderTotalFormatted;
      }

      return this.priceDifference;
    },
    infoMessage(): { text: string; slot0?: string } | string {
      if (this.amountToPay !== null && this.amountToPay > 0) {
        return {
          text: 'shared.summaryLounges.originalPriceIsLower',
          slot0: 'shared.summaryLounges.originalPriceIsLowerSlot0',
        };
      }
      if (this.amountToPay !== null && this.amountToPay < 0) {
        return {
          text: 'shared.summaryLounges.originalPriceIsHigher',
          slot0: 'shared.summaryLounges.originalPriceIsHigherSlot0',
        };
      }
      if (this.amountToPay === 0) {
        return {
          text: 'shared.summaryLounges.sameAsOriginalPrice',
          slot0: 'shared.summaryLounges.sameAsOriginalPriceSlot0',
        };
      }
      if (this.refundDoesNotExist) {
        return {
          text: 'shared.summaryLounges.refundDoesNotExist',
        };
      }
      if (this.refundProcessing) {
        return {
          text: 'shared.summaryLounges.refundProcessing',
        };
      }
      return '';
    },

    /**
     * Checks if there is a deposit but checking for the existence of a null
     * value or not
     *
     * @returns Returns true if there is a deposit, otherwise false.
     */
    hasDeposit(): false | { payNow: string, payOnArrival: string } {
      const depositOnly = readDepositOnly(this.$store);
      if (depositOnly === null) {
        return false;
      }

      return depositOnly;
    },

    /**
     * Returns the summary text based on state of product.
     * If a deposit has been paid, it returns 'Deposit paid',
     * otherwise it returns the translated value of 'shared.summaryParkings.totalPaid'.
     *
     * @return {VueI18n.TranslateResult} The summary text.
     */
    summaryText(): VueI18n.TranslateResult {
      if (this.hasDeposit) {
        return this.$t('shared.depositPaid');
      }

      return this.$t('shared.summaryLounges.totalPaid');
    },

    /**
     * Checks if the current page is a receipt page.
     *
     * @returns Returns true if the current page is a receipt page, false otherwise.
     */
    isReceiptPage(): boolean {
      // @ts-ignore
      return this.$route && this.$route.name.includes('receipt');
    },

    /**
     * Determines if the current route is a manage booking page.
     *
     * @returns True if the current route is a manage booking page, otherwise false.
     */
    isManageBookingPages(): boolean {
      // @ts-ignore
      return this.$route && this.$route.name.includes('manage-booking');
    }
  },
  methods: {
    onClickHandler() {
      this.isExpanded = !this.isExpanded;
    },
    onClickCancellationProtectionHandler() {
      this.isCancellationProtectionExpanded =
        !this.isCancellationProtectionExpanded;
    },
  },
});
