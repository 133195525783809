
import { defineComponent } from 'vue';
// Mixins
import auth from '@white-label-helper/mixin-auth';
import { readManageBookingItems, readId } from '@white-label-store/manage-booking';
import { required } from '@white-label-helper/vuelidate';

import DropdownWrapper from '../dropdown-wrapper/dropdown-wrapper.vue';
import RadioButton from '../radio-button/radio-button.vue';
import TextEditor from '../text-editor/text-editor.vue';
import BackButton from '../back-button/back-button.vue';
import { NAMED_ROUTES } from '@white-label-configuration/constants';
import {
  getBookingBaggageDetails,
  setBookingBaggageDetails,
} from '@white-label-helper/api-manage-booking';

export default defineComponent({
  name: 'BookingBaggageDetails',
  mixins: [auth],
  data() {
    return {
      baggage: {
        baggage_count: { id: '1', name: '1' },
        has_oversized_baggage: false,
        declaration_required: false,
        declaration_comment: '',
      },
      bagsOpened: false,
      specialBaggageOptions: {
        false: this.$t('bookings.baggageDetails.specialBaggage.dontHave'),
        true: this.$t('bookings.baggageDetails.specialBaggage.have'),
      },
      customDeclarationOptions: {
        false: this.$t('bookings.baggageDetails.customDeclaration.dontHave'),
        true: this.$t('bookings.baggageDetails.customDeclaration.have'),
      },
    };
  },
  components: {
    DropdownWrapper,
    RadioButton,
    BackButton,
    TextEditor,
  },
  async mounted() {
    const data = await getBookingBaggageDetails(
      this.getToken,
      this.getManagementToken,
      this.getId
    );
    if (!data) return;
    this.baggage = data;
    this.baggage.baggage_count = {
      id: this.baggage.baggage_count,
      name: this.baggage.baggage_count,
    };
  },
  validations(): object {
    return {
      baggage: {
        baggage_count: { required },
        has_oversized_baggage: { required },
        declaration_required: { required },
        declaration_comment: { required },
      },
    };
  },
  computed: {
    customError() {
      return this.$t('bookings.baggageDetails.validationErrors.declare');
    },
    bagsSelections() {
      const array = [];
      Array(16)
        .fill(0)
        .forEach((_, i) => {
          if (i > 0) {
            array.push({ id: i + '', name: i + '' });
          }
        });
      array.push({
        id: '16+',
        name: '16+',
      });
      return array;
    },
    getManagementToken() {
      const token = this.$cookies.get('manageBookingToken')?.token;

      return token;
    },
    getId() {
      const id = this.$cookies.get('manageBookingToken')?.id;
      return id || readId(this.$store);
    },
    manageBookingItems() {
      return (
        readManageBookingItems(this.$store).find(
          (booking) => booking.id === this.getId
        ) || {}
      ).inventory_item;
    },
  },
  methods: {
    goToBookingGuests() {
      this.$router.push(NAMED_ROUTES.booking_guest_details.home);
    },
    update(value, field) {
      this.baggage[field] = value;
    },
    submit() {
      this.$v.baggage.$touch();
      const baggageData = {
        ...this.baggage,
        baggage_count: (this.baggage.baggage_count || {}).name,
      };
      if (!this.baggage.declaration_comment) return;
        setBookingBaggageDetails(
          this.getToken,
          this.getManagementToken,
          this.getId,
          baggageData
        ).then((res) => {
          if (res) this.goToBookingGuests();
        });

    },
  },
});
