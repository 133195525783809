
import { defineComponent, PropType } from 'vue';
import type { Discount, Tax, Fee } from '@white-label-types/parking-checkout';
import type { Extras } from '@white-label-types/parking-booking';
import type VueI18n from 'vue-i18n';
import IconMessage from '../icon-message/icon-message.vue';
import EntryExitInfo from '../entry-exit-info/entry-exit-info.vue';
import SummaryPreloader from '../summary-preloader/summary-preloader.vue';
import SummaryProduct from '../summary-product/summary-product.vue';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import type { SummaryParking } from './summary-parking';
import SummaryProductItem from '../summary-product-item/summary-product-item.vue';
import SummarySubtitle from '../summary-subtitle/summary-subtitle.vue';
import { readDepositOnly } from '@white-label-store/deposits';


export default defineComponent<
  SummaryParking.Props,
  {},
  SummaryParking.Data,
  SummaryParking.ComputedProps
>({
  name: 'SummaryParkings',

  components: {
    EntryExitInfo,
    IconAlert: () => import('@white-label-icon/icon-alert'),
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconCheck: () => import('@white-label-icon/icon-check'),
    IconMessage,
    SummaryPreloader,
    SummaryListItem: SummaryProductItem,
    SummaryProduct,
    SummarySubtitle,
  },

  props: {
    amountToPay: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },

    cancellationFee: {
      type: String,
      default: '',
    },

    refundProcessing: {
      type: Boolean,
    },

    originalTotalPrice: {
      type: String,
      default: '',
    },

    newTotalPrice: {
      type: String,
      default: '',
    },

    contentLoading: {
      type: Boolean,
    },

    isModifyFlowReceipt: {
      type: Boolean,
    },

    hasPaid: {
      type: Boolean,
    },

    orderTotal: {
      type: String,
      default: '',
    },

    itemSubtotal: {
      type: String,
      default: '',
    },

    itemTotal: {
      type: String,
      default: '',
    },

    itemTotalOld: {
      type: String,
      default: '',
    },

    orderOriginalTotal: {
      type: String,
      default: '',
    },

    amendmentFee: {
      type: String,
      default: '',
    },

    originalOrderHasCancellationProtection: {
      type: Boolean,
    },

    priceDifference: {
      type: String,
      default: '',
    },

    orderName: {
      type: String,
      default: '',
    },

    entryDateTime: {
      type: String,
      default: '',
    },

    exitDateTime: {
      type: String,
      default: '',
    },

    extras: {
      type: Object as PropType<Extras>,
      default: function () {
        return {};
      },
    },

    cancellationProtection: {
      type: String,
      default: '',
    },

    taxes: {
      type: Array as PropType<Tax[]>,
      default: function () {
        return [];
      },
    },

    fees: {
      type: Array as PropType<Fee[]>,
      default: function () {
        return [];
      },
    },

    discount: {
      type: Object as PropType<Discount>,
      default() {
        return {
          code: '',
          amount: 0,
          description: '',
          subtotal: 0,
          total: 0,
          amountFormatted: '',
          subtotalFormatted: '',
          totalFormatted: '',
        };
      },
    },

    isRefund: {
      type: Boolean,
    },

    refundDoesNotExist: {
      type: Boolean,
    },

    nonRefundable: {
      type: Array as PropType<{ name: string; price_formatted: string }[]>,
      default: function () {
        return [];
      },
    },

    valueAfterRefundWithoutCancellation: {
      type: String,
      default: '',
    },

    bookingFee: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      cancellationTaxesExpanded: false,
      taxesAndFeesExpanded: false,
    };
  },

  computed: {
    taxesComputed(): Tax[] {
      return !getAppVariable('is_tax_inclusive') ? this.taxes : [];
    },

    feesComputed(): Fee[] {
      return !getAppVariable('is_fee_inclusive') ? this.fees : [];
    },

    showCancellationTaxes(): boolean {
      return (
        !!this.extras?.items?.length && !getAppVariable('is_tax_inclusive')
      );
    },

    /**
     * Checks if there is a deposit but checking for the existence of a null
     * value or not
     *
     * @returns Returns true if there is a deposit, otherwise false.
     */
    hasDeposit(): false | { payNow: string, payOnArrival: string } {
      const depositOnly = readDepositOnly(this.$store);
      if (depositOnly === null) {
        return false;
      }

      return depositOnly;
    },

    totalLabel(): VueI18n.TranslateResult {
      // When on recipe or account pages show normal text
      if (this.hasDeposit && !(this.isReceiptPage || this.isManageBookingPages)) {
        return this.$t('shared.payNow');
      }

      if (this.isModifyFlowReceipt) {
        if (this.amountToPay < 0) {
          return this.$t('shared.refundAmount');
        }
        if (this.amountToPay > 0) {
          return this.$t('shared.amountPaid');
        }
        return this.$t('shared.priceDifference');
      }
      if (this.amountToPay > 0) {
        return this.$t('shared.amountToPay');
      }
      if (this.isRefund) {
        if (this.amountToPay === 0) {
          return this.$t('shared.totalToPay');
        }

        if (this.hasDeposit) {
          return this.$t('shared.amountRefund');
        }
        return this.$t('shared.refundAmount');
      }
      if (this.hasPaid) {
        return this.$t('shared.totalPaid');
      }
      return this.$t('shared.totalToPay');
    },

    totalValue(): string {
      if (this.hasDeposit) {
        return this.hasDeposit.payNow;
      }

      if (!this.isModifyFlowReceipt) {
        if (this.valueAfterRefundWithoutCancellation) {
          return this.valueAfterRefundWithoutCancellation;
        }
        return this.isRefund || this.isModifyCheckout
          ? this.priceDifference
          : this.orderTotal;
      }
      return this.priceDifference;
    },

    isExtrasExists(): number | null | undefined {
      return Object.keys(this.extras).length || this.extras?.discount?.amount;
    },

    hasDiscount(): boolean {
      return Boolean(this?.discount?.amount);
    },

    hasCancellationProtection(): boolean {
      return !!this.cancellationProtection;
    },

    infoMessage(): { text: string; slot0?: string } | string {
      if (this.amountToPay > 0) {
        return {
          text: 'shared.summaryParkings.originalPriceIsLower',
          slot0: 'shared.summaryParkings.originalPriceIsLowerSlot0',
        };
      }
      if (this.amountToPay < 0) {
        return {
          text: 'shared.summaryParkings.originalPriceIsHigher',
          slot0: 'shared.summaryParkings.originalPriceIsHigherSlot0',
        };
      }
      if (this.amountToPay === 0) {
        return {
          text: 'shared.summaryParkings.sameAsOriginalPrice',
          slot0: 'shared.summaryParkings.sameAsOriginalPriceSlot0',
        };
      }
      if (this.refundDoesNotExist) {
        return {
          text: 'shared.summaryParkings.refundDoesNotExist',
        };
      }
      if (this.refundProcessing) {
        return {
          text: 'shared.summaryParkings.refundProcessing',
        };
      }

      return '';
    },

    displaySummaryBottomSection(): boolean {
      return this.isRefund && !this.isModifyCheckout;
    },

    displaySummaryBottomSectionMB(): string {
      return this.originalTotalPrice && this.newTotalPrice;
    },

    displaySummaryBottomSectionCancelled(): boolean | string {
      return this.hasCancellationProtection || this.cancellationFee;
    },

    displayEntryExitInfo(): string {
      return this.entryDateTime && this.exitDateTime;
    },

    isModifyCheckout(): boolean {
      return !!this.orderOriginalTotal;
    },

    isCancellationPage(): boolean {
      return Boolean(this.cancellationFee);
    },

    taxesAndFeesLabel(): VueI18n.TranslateResult {
      if (this.taxesComputed.length && this.feesComputed.length) {
        return this.$t('shared.summaryParkings.taxesAndFees');
      }
      if (this.taxesComputed.length) {
        return this.$t('shared.summaryParkings.taxes');
      }
      return this.$t('shared.summaryParkings.fees');
    },

    // TODO: remove feature flag when ECOM-997 is ready for live
    displaySummarySubtitleBookingFee(): boolean {
      return (
        !!this.bookingFee &&
        this.isCancellationPage &&
        this.$launchDarkly?.variation('ECOM-997') === true
      );
    },

    displaySummaryTotalBookingFee(): boolean {
      return (
        Boolean(this.bookingFee) &&
        !this.isCancellationPage &&
        // remove condition below when ECOM-997 will be ready for live
        this.$launchDarkly?.variation('ECOM-997') === true
      );
    },

    /**
     * Returns the summary text based on state of product.
     * If a deposit has been paid, it returns 'Deposit paid',
     * otherwise it returns the translated value of 'shared.summaryParkings.totalPaid'.
     *
     * @return {VueI18n.TranslateResult} The summary text.
     */
    summaryText(): VueI18n.TranslateResult {
      if (this.hasDeposit) {
        return this.$t('shared.depositPaid');
      }

      return this.$t('shared.summaryLounges.totalPaid');
    },

    /**
     * Checks if the current page is a receipt page.
     *
     * @returns Returns true if the current page is a receipt page, false otherwise.
     */
    isReceiptPage(): boolean {
      // @ts-ignore
      return this.$route && this.$route.name.includes('receipt');
    },

    /**
     * Determines if the current route is a manage booking page.
     *
     * @returns True if the current route is a manage booking page, otherwise false.
     */
    isManageBookingPages(): boolean {
      // @ts-ignore
      return this.$route && this.$route.name.includes('manage-booking');
    }
  },
});
