import { httpBooking } from '@white-label-configuration/http';
import { parseManageBookingState } from '@white-label-helper/data-parsers-manage-booking';

// Types
import type { User } from '@white-label-types/parking-shared';

interface UpdateBookingDetailsBody {
    order_reference: string,
    user: User,
}

interface ResendConfirmation {
    email: string,
    entry_date: string
}

interface ResendConfirmationType extends ResendConfirmation {
  product_type: 'lounges' | 'parking'
}

interface SearchBookingBody {
    email: string,
    reference_id: string | (string | null)[],
}

interface CancelBookingBody {
  order_item_reference: string,
  reason: string,
  meta: Record<string, unknown>,
}

interface BaggageDetailsBody {
  baggage_count: string;
  has_oversized_baggage: boolean;
  declaration_required: boolean;
  declaration_comment: string;
}

export const updateBookingDetails = (body: UpdateBookingDetailsBody) => (
  httpBooking.put('pii-data', { body })
);

export const getManageBookingState = (manageBookingToken: string) => (
  httpBooking.get('account/booking/management', { headers: { 'Management-Token': manageBookingToken } })
    .then((res) => parseManageBookingState(res.body.data))
);

export const resendConfirmation = (body: ResendConfirmation | ResendConfirmationType) => (
  httpBooking.post('booking/receipt/resend', { body })
    .then((data) => data.body)
);

export const searchBooking = (body: SearchBookingBody) => (
  httpBooking.post('account/booking/management/token', { body })
    .then((res) => res.body.data)
);

export const cancellationCancelBooking = (manageBookingToken: string, body: CancelBookingBody) => (
  httpBooking.patch('booking/order/item', { headers: { 'Management-Token': manageBookingToken }, body })
);

export const cancellationEstimateBooking = (manageBookingToken: string) => (
  httpBooking.get('/booking/cancel-receipt/estimate', { headers: { 'Management-Token': manageBookingToken }})
    .then((res) => parseManageBookingState(res.body.data))
);

export const cancellationReceiptBooking = (manageBookingToken: string) => (
  httpBooking.get('/booking/cancel-receipt', { headers: { 'Management-Token': manageBookingToken }})
    .then((res) => res.body.data)
);

export const getBookingBaggageDetails = (bearerToken: string, manageBookingToken: string, orderItemId: string) => (
  httpBooking.get(`account/pii-questions-data/${orderItemId}/baggage-config`, {  headers: { Authorization: bearerToken, 'Management-Token': manageBookingToken },})
    .then((res) => res.body.data)
    .catch(err => console.log(err))
);

export const setBookingBaggageDetails = (bearerToken: string, manageBookingToken: string, orderItemId: string, body: BaggageDetailsBody) => (
  httpBooking.post(`account/pii-questions-data/${orderItemId}/baggage-config`, { body, headers: { Authorization: bearerToken, 'Management-Token': manageBookingToken }})
    .then((res) => res.body.data)
    .catch(err => console.log(err))
);
