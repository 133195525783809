
// Types
import type {
  Cart,
  CartItem,
  CheckoutCreateCancellationProtectionCartItem,
  MetaData as HandlePaymentCompleteMetaData,
  Payload as HandlePaymentCompletePayload,
  PaymentSummaryProps,
  User as HandlePaymentCompleteUser,
  CancellationProtection,
} from '@white-label-types/parking-checkout';
import type { PusherEvent } from '@white-label-helper/pusher-handle-payment-request';
import type {
  ExtrasCancellationProtectionProduct,
  POI,
} from '@white-label-types/parking-booking';

// Packages
import { defineComponent } from 'vue';
import { Portal } from 'portal-vue';

// Helpers
import {
  gaDataLayer,
  gaDataLayerLounges,
} from '@white-label-helper/ga-data-layer';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import {
  goToApp,
  getDomainUrl,
  MANAGE_BOOKING_URLS,
  DOMAINS_NAMES,
} from '@white-label-helper/switch-app';
import {
  trackBeginCheckout,
  refreshDataLayer,
} from '@white-label-helper/ga-tracking';
import { parseDiscountCodeData } from '@white-label-helper/discount-code-parser';
import { fetchCartItems } from '@white-label-helper/api-parking-cart';
import { handlePaymentHelper } from '@white-label-helper/pusher-handle-payment-request';
import { continueBookingHelper } from '@white-label-helper/pusher-continue-booking';
import { formatPrice } from '@white-label-helper/helper-payment';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { updateCancellationProtectionRequest } from '@white-label-helper/api-parkings-cart';

// Mixins
import checkout from '@white-label-helper/mixin-checkout';
import multiBasket from '@white-label-helper/mixin-multi-basket';

// Stores
import { commitUpdatePreloader } from '@white-label-store/preloader-controller';
import {
  readDiscountObject,
  readDiscountQuery,
  commitDiscountCode,
  commitStoreDeepLinkDiscount,
} from '@white-label-store/discount-code';
import {
  commitCartItems,
  commitUpdateCancellationProtection,
  readCartItems
} from '@white-label-store/cart-checkout';
import {
  commitDepositOnly
} from '@white-label-store/deposits';

// Constants
import {
  ContinueBookingStatuses,
  PAYMENT_PROVIDERS,
} from '@white-label-configuration/constants';

const {
  handlePayment,
  destroyHandlePayment,
  handleBookingOrderWithoutPayment,
} = handlePaymentHelper('original');
const { continueBooking: continueOriginalBooking, destroyContinueBoooking } =
  continueBookingHelper('original');

import BackButton from '../back-button/back-button.vue';
import PaymentForm from '../payment-form/payment-form.vue';
import PaymentFormPreloader from '../payment-form-preloader/payment-form-preloader.vue';
import SummaryLounges from '../summary-lounges/summary-lounges.vue';
import SummaryParking from '../summary-parking/summary-parking.vue';
import PaymentSummary from '../payment-summary/payment-summary.vue';
import SummaryPreloader from '../summary-preloader/summary-preloader.vue';
import StickySidebar from '../sticky-sidebar/sticky-sidebar.vue';

export default defineComponent({
  name: 'PaymentPage',

  handlePayment,
  destroyHandlePayment,
  continueOriginalBooking,
  destroyContinueBoooking,
  handleBookingOrderWithoutPayment,

  components: {
    BackButton: BackButton,
    PaymentForm: PaymentForm,
    PaymentFormPreloader: PaymentFormPreloader,
    Portal,
    StickySidebar,
    SummaryLounges,
    PaymentSummary,
    SummaryParkings: SummaryParking,
    SummaryPreloader,
  },
  filters: {
    formatPrice,
  },

  mixins: [checkout, multiBasket],

  computed: {
    summaryProps(): PaymentSummaryProps {
      return {
        orderName: this.checkout_orderName,
        orderTotal: this.checkout_orderTotalFormatted,
        itemSubtotal: this.checkout_itemSubtotalFormatted,
        itemTotal: this.checkout_itemTotalFormatted,
        entryDateTime: this.checkout_entryDateTime,
        exitDateTime: this.checkout_exitDateTime,
        taxes: this.checkout_taxes,
        fees: this.checkout_fees,
        extras: this.checkout_summaryExtras,
        discount: readDiscountObject(this.$store),
        contentLoading: this.$contentPreloaderEnabled,
        itemTotalOld: formatPrice(
          this.checkout_cartItem?.original_amounts.totals.total
        ),
        bookingFee: this.checkout_bookingFee?.is_enabled
          ? formatPrice(this.checkout_bookingFee.amount)
          : '',
      };
    },
    displayNewSummaries() {
      return this['$launchDarkly'].variation('PT-1566-DISPLAY-NEW-PAYMENT-SUMMARIES');
    },
    displayTravelExtrasSummary(){
      return this['$launchDarkly'].variation('ECOM-1770-Travel-Extras-Payment-Summary');
    },
    isCancellationProtectionAvailable(): boolean | undefined {
      if (this.isCrossSellExtrasPageActive) {
        return this.cartItems?.some(
          (item: CartItem) =>
            item.cancellation_protection?.is_available
        );
      }
      return this.checkout_cartItem?.inventory_option?.details
        ?.cancellation_protection?.is_available;
    },
    isCrossSellExtrasPageActive(): boolean {
      // TODO: Remove when `ECOM_1771_MOVE_CANCELLATION_PROTECTION` is live
      return this.$launchDarkly.variation(
        'ECOM_1771_MOVE_CANCELLATION_PROTECTION'
      );
    },
    cartItems(): CartItem[] {
      return readCartItems(this.$store);
    },
    additionalFields() {
      return this['isMultiBasket'] || this.$launchDarkly.variation('ECOM_1877_CROSS_SELL_PRE_PURCHASE')
        ? this.checkout_multiCartAdditionalFields
        : this.checkout_additionalFields;
    },
    cancellationProtection(): CancellationProtection[] {
      // TODO: Remove conditional when `ECOM_1771_MOVE_CANCELLATION_PROTECTION` is live

      // Always treat cancellationProtection as an array
      return this.isCrossSellExtrasPageActive
        ? this.checkout_multiCartCancellationProtection
        : [this.checkout_cancellationProtection];
    },
    cancellationProtectionProductIds(): string[] {
      return this.cancellationProtection
        .filter((item) => item?.is_available)
        .map((item) => item?.product_id);
    },
    isProtected(): boolean {
      return this.cartItems?.some((item: CartItem) => item.is_protected);
    },
  },
  created() {
    commitCartItems(this.$store, []);
  },

  methods: {
    async getCart() {
      try {
        const cartItems = (await fetchCartItems(
          this.checkout_cartToken
        )) as Cart;
        commitDepositOnly(this.$store, {
          ...cartItems.payable,
          isEnabled: this.$launchDarkly.variation('gtm-322-deposit-only-products')
        });
        commitCartItems(this.$store, cartItems.items);
        // now we get a successful response even for a non-existent cart
        // to check if cart not empty we look at the length of the array
        if (cartItems.items.length) {
          // if we have discount code applied to cart - put it to store to be displayed
          // TODO: create cart dataParser for that instead of reusing discountDataParser
          const discountAppliedToCart = parseDiscountCodeData(cartItems);
          if (discountAppliedToCart) {
            commitDiscountCode(this.$store, discountAppliedToCart);
          }
          const {
            items,
            valid_till,
            is_valid,
            totals,
            original_amounts,
            booking_fee,
          }: {
            items: Cart['items'];
            valid_till: Cart['valid_till'];
            is_valid: Cart['is_valid'];
            totals: Cart['totals'];
            original_amounts: Cart['original_amounts'];
            booking_fee: Cart['booking_fee'];
          } = cartItems;

          const item = items[0];
          const couponCode = item.search_criteria?.parking?.coupon_code;

          this.checkout_cartItems = items;
          this.checkout_originalAmounts = original_amounts;
          this.checkout_bookingFee = booking_fee;
          commitStoreDeepLinkDiscount(
            this.$store,
            typeof couponCode !== 'string' ? '' : couponCode
          );
          this.checkout_total = totals;
          // @ts-ignore - Mixin method
          this.checkout_revalidateCartRecursive(is_valid, true, valid_till);
          this.$global_disableContentPreloader();

          refreshDataLayer(this.$gtm);

          // @ts-ignore
          const query = this.checkout_getQueryForGoBack();
          if (this.isParkingProduct) {
            // If multiple POIs are available, we need to get the correct one
            const poi = getAppVariable('pois').find(
              (poi: POI) => poi.code === query.airport
            );

            this.$gtm.push(
              gaDataLayer({
                airportCode: poi.code,
                airportName: poi.name,
                partnerName: getAppVariable('partner_name'),
                siteLanguage: getAppVariable('default_language'),
                airportTerminalId: query.terminal,
                parkingDepartureDate: query.entryDate,
                parkingDepartureTime: query.entryTime,
                parkingReturnDate: query.exitDate,
                parkingReturnTime: query.exitTime,
                couponVoucher: query.discount || undefined,
                carParkName: this.checkout_orderName,
              })
            );
          }

          if (this.isLoungesProduct) {
            this.$gtm.push(
              gaDataLayerLounges(item.search_criteria?.lounges, {
                loungeName: this.checkout_orderName,
              })
            );
          }

          const trackingItems: [
            CartItem,
            CheckoutCreateCancellationProtectionCartItem?
          ] = [item];

          if (item.is_protected) {
            // @ts-ignore - Mixin method
            trackingItems.push(
              this.checkout_createCancellationProtectionCartItem(item)
            );
          }

          const metaIndex = item?.meta?.filter?.index;
          const metaItemListName = item?.meta?.filter?.item_list_name;
          const bookingFee = booking_fee?.amount;

          const params =
            metaIndex && metaItemListName
              ? [this.$gtm, trackingItems, bookingFee, metaIndex, metaItemListName]
              : [this.$gtm, trackingItems, bookingFee];

          if (this.isLoungesProduct) {
            params.push('lounges');
          }

          // @ts-ignore - Wrong product type
          trackBeginCheckout(...params);
        } else {
          // @ts-ignore - Mixin method
          this.checkout_showTechnicalIssueError();
        }
      } catch (e) {
        // @ts-ignore - Mixin method
        this.checkout_showTechnicalIssueError();
      }
    },

    handlePaymentCompleted(payload: {
      payload: HandlePaymentCompletePayload;
      userDetails: HandlePaymentCompleteUser;
      metaData: HandlePaymentCompleteMetaData;
    }) {
      if (
        process.env.NUXT_ENV_NEW_PAYMENT_ONLY_STRATEGY &&
        process.env.NUXT_ENV_PAYMENT_PROVIDER === PAYMENT_PROVIDERS.STRIPE &&
        this.checkout_orderTotal !== 0
      ) {
        this.continueBooking(ContinueBookingStatuses.Successful);
      } else {
        this.createBooking(payload);
      }
    },

    handleStripePaymentIntentPayload(payload: {
      payload: HandlePaymentCompletePayload;
      userDetails: HandlePaymentCompleteUser;
      metaData: HandlePaymentCompleteMetaData;
    }) {
      this.createBooking(payload);
    },

    createBooking({
      payload,
      userDetails,
      metaData,
    }: {
      payload: HandlePaymentCompletePayload;
      userDetails: HandlePaymentCompleteUser;
      metaData: HandlePaymentCompleteMetaData;
    }) {
      const body = {
        cart_token: this.checkout_cartToken,
        amount: this.checkout_orderTotal,
        currency: this.checkout_currency,
        user: { ...userDetails },
        ...(!isBookingPortal
          ? {
              gateway_data: {
                ...payload,
                payment_strategy:
                  process.env.NUXT_ENV_NEW_PAYMENT_ONLY_STRATEGY &&
                  process.env.NUXT_ENV_PAYMENT_PROVIDER ===
                    PAYMENT_PROVIDERS.STRIPE
                    ? 'NewPaymentOnly'
                    : 'SaveCardPaymentMethod',
              },
            }
          : {}),
        ...(isBookingPortal
          ? {
              meta: {
                ...metaData,
              },
            }
          : {}),
      };

      commitUpdatePreloader(
        this.$store,
        this.$t('UI.fullscreenPreloaderMessages.nearlyThere')
      );
      if (isBookingPortal) {
        handleBookingOrderWithoutPayment(this.checkout_cartToken, body)
          .then(() => {
            this.logoutBookingPortal();
          })
          .catch(this.checkout_handlePaymentError);
      } else {
        handlePayment(this.checkout_cartToken, body)
          .then((data: PusherEvent) => {
            // @ts-ignore mixin variable
            this.checkout_forwardInfo = data.forward;

            if (
              process.env.NUXT_ENV_NEW_PAYMENT_ONLY_STRATEGY &&
              process.env.NUXT_ENV_PAYMENT_PROVIDER ===
                PAYMENT_PROVIDERS.STRIPE &&
              this.checkout_orderTotal !== 0
            ) {
              // @ts-ignore mixin property
              this.checkout_paymentsConfig.token = data.payment_client_secret;
              // @ts-ignore mixin property
              this.checkout_paymentIntentUpdated = true;
            } else {
              this.continueBooking(ContinueBookingStatuses.Successful);
            }
          })
          .catch(this.checkout_handlePaymentError);
      }
    },

    continueBooking(status: string) {
      const body = {
        status,
        // @ts-ignore mixin variable
        forward: this.checkout_forwardInfo,
      };
      continueOriginalBooking(this.checkout_cartToken, body)
        .then(
          ({
            management_token,
            order_reference,
          }: {
            management_token: { token: string; expiration: number };
            order_reference: string;
          }) => {
            // @ts-ignore - Mixin method
            this.checkout_onBookingComplete(
              {
                id: order_reference,
                affiliation: getAppVariable('partner_name'),
                revenue: this.checkout_orderTotal,
                coupon: '',
              },
              {
                name: this.checkout_orderName,
                id: this.checkout_cartItem.id,
                price: this.checkout_orderTotal,
                category: this.checkout_cartItem.product_code,
                quantity: 1, // Quantity, set to 1 for MVP
              }
            );
            // @ts-ignore - Mixin method
            this.checkout_onSuccessCleaningStorage();
            // @ts-ignore - Mixin method
            this.checkout_setCookiesAndGoToReceipt(
              management_token,
              'BOOKING_RECEIPT'
            );
          }
        )
        .catch((error: { errorType: string; error: string; type: string }) => {
          if (status === ContinueBookingStatuses.Failed) {
            this.checkout_forwardInfo = null;
            commitUpdatePreloader(this.$store, '');
          } else {
            // @ts-ignore mixin method
            this.checkout_handlePaymentError(error);
          }
        });
    },
    goToSecondStep() {
      // @ts-ignore - Mixin method
      goToApp(
        getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
        MANAGE_BOOKING_URLS.BOOKING_SEARCH,
        readDiscountQuery(this.$store)
      );
    },
    onGoBack(destination: string) {
      if (destination === 'extras') {
        goToApp(
          getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
          MANAGE_BOOKING_URLS.SELECT_EXTRAS,
          readDiscountQuery(this.$store)
        );
      } else {
        goToApp(
          getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
          MANAGE_BOOKING_URLS.BOOKING_SEARCH,
          readDiscountQuery(this.$store)
        );
      }
    },

    updateItems(items: CartItem[] | []) {
      this.checkout_cartItems = items;
    },

    updateOrderTotals(orderTotals: Cart['totals']) {
      this.checkout_total = orderTotals;
    },

    handleAddDiscountError() {
      // If we receive an error when adding a discount and another discount was added before that
      // We return the values to the original (without discount) from 'original_amounts' property
      if (this.checkout_cartItem.discount) {
        this.$store.commit('discountCode/clearDiscountCode');
        if (this.isParkingProduct) {
          this.checkout_total = this.checkout_originalAmounts;
          this.checkout_cartItems = [
            {
              ...this.checkout_cartItem,
              totals: this.checkout_cartItem.original_amounts.totals,
              fees: this.checkout_cartItem.original_amounts.fees,
              taxes: this.checkout_cartItem.original_amounts.taxes,
              discount: null,
            },
          ];
        } else if (this.isLoungesProduct) {
          this.checkout_total = this.checkout_originalAmounts;
          this.checkout_cartItems = this.cartItems
        }
      }
    },

    handleWidgetError() {
      // @ts-ignore mixin variable
      if (this.checkout_forwardInfo) {
        this.continueBooking(ContinueBookingStatuses.Failed);
        this.checkout_paymentIntentUpdated = false;
      }
    },

    addCancellationProtection(): void {
      this.toggleCancellationProtection(this.cancellationProtectionProductIds, true);
    },

    removeCancellationProtection(): void {
      this.toggleCancellationProtection(this.cancellationProtectionProductIds, false);
    },

    toggleCancellationProtection(productIds: string[], isProtected: boolean): void {
      const toggleCancellationProtectionRecursive = (index: number) => {
        if (index >= productIds.length) {
          return;
        }

        const productId = productIds[index];
        updateCancellationProtectionRequest<
          ExtrasCancellationProtectionProduct,
          Cart['totals'],
          Cart['payable']
        >(productId, this.checkout_cartToken, {
          is_protected: isProtected,
        }).then(({ totals, payable }) => {
          this.checkout_total = totals;
          commitDepositOnly(this.$store, {...payable, isEnabled: this.$launchDarkly.variation('gtm-322-deposit-only-products')});
          commitUpdateCancellationProtection(this.$store, { productId, isProtected });

          toggleCancellationProtectionRecursive(index + 1);
        });
      };

      toggleCancellationProtectionRecursive(0);
    }
  },
});
